<template>
  <div>
    <InlineMessageBox header="Important Refund Information" site="buyer">
      <ul>
        <div v-for="(item, index) in bulletPoint.items" :key="index">
          <li>
            {{ item }}
          </li>
        </div>
      </ul>
    </InlineMessageBox>
    <!-- <bullet-point-box :title="bulletPoint.title" :items="bulletPoint.items" /> -->
    <FormulateForm
      name="refundForm"
      v-model="formData"
      @submit="submit"
      #default="{ hasErrors }"
      autocomplete="false"
    >
      <div class="row">
        <div class="col half">
          <FormulateInput
            name="refund"
            type="select"
            :options="refundOption"
            label="Refund Options"
            placeholder="- Please Select -"
            validation="required"
            ignored
            v-model="orderOrTicket"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            name="ticket"
            type="select"
            v-model="currentSelectedObj"
            :options="orderOrTicketList"
            :label="currentDisplayLabel"
            placeholder="- Please Select -"
            @input="getCurrentTicketValue()"
            ignored
            autocomplete="false"
          />
          <!-- <FormulateInput
            name="ticket"
            type="select"
            :options="ticketList"
            label="Select Ticket"
            placeholder="Auto Selected"
            validation="required"
            @input="autoCompleteUserInfo"
          /> -->
        </div>
      </div>

      <div class="row">
        <div class="col half">
          <FormulateInput
            name="firstName"
            label="First Name"
            placeholder="Enter First Name"
            validation="required"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            name="lastName"
            label="Last Name"
            placeholder="Enter Last Name"
            validation="required"
            autocomplete="false"
          />
        </div>
      </div>

      <div class="row">
        <div class="col half">
          <FormulateInput
            name="email"
            label="Email"
            placeholder="Enter Email"
            validation="required|email"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            placeholder="1 (555) 555-5555"
            validation="required"
            autocomplete="false"
          />
        </div>
      </div>

      <div class="row">
        <div class="col full">
          <FormulateInput
            name="reasonForRefund"
            label="Reason for Refund"
            placeholder="Enter text here"
            validation="required"
            autocomplete="false"
          />
        </div>
      </div>
      <div class="button-refund">
        <FormulateInput
          type="button"
          name="Send Request"
          :disabled="hasErrors"
          @click="confirm"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
const ORDER = "Order";
const TICKET = "Ticket";

const CURRENT = "Current";
const CURRENT_ORDER = "Current Order";
const CURRENT_TICKET = "Current Ticket";

const UPGRADE = "Upgrade";
const UPGRADE_ORDER = "Upgrade Order";
const UPGRADE_TICKET = "Upgrade Ticket";

import BulletPointBox from "@/components/BulletPointBox.vue";
import InlineMessageBox from "@/components/InlineMessageBox.vue";
import dateFormat from "dateformat";

const TITLE = "Important Refund Information";
const ITEM01 = "Refunds are offered under terms set by the event planner.";
const ITEM02 =
  "Filling out this form will send a request to the planner who will approve or deny the refund.";
const ITEM03 =
  "If you can't select the options below you have not purchased tickets to any events which offer this feature.";

export default {
  name: "refund",
  head() {
    return {
      title: "Refunds",
    };
  },
  components: {
    BulletPointBox,
    InlineMessageBox,
  },
  data() {
    return {
      bulletPoint: {
        title: TITLE,
        items: [ITEM01, ITEM02, ITEM03],
      },
      orderOrTicket: "",
      formData: {
        email: "",
      },
      refundOption: [ORDER, TICKET],
      orderList: [],
      ticketList: [],
      currencySign: "$",
      currentSelectedObj: {},
      eventUrl: "",
    };
  },
  computed: {
    currentDisplayLabel() {
      return this.orderOrTicket === ORDER
        ? CURRENT_ORDER
        : this.orderOrTicket === TICKET
        ? CURRENT_TICKET
        : CURRENT;
    },
    orderOrTicketList() {
      if (this.orderOrTicket === ORDER) {
        return this.orderList;
      } else if (this.orderOrTicket === TICKET) {
        return this.ticketList;
      } else {
        return [];
      }
    },
  },
  watch: {
    "formData.email"(newVal) {
      this.removeSpaces(newVal, "onlyemail");
    },
  },
  methods: {
    removeSpaces(val, type) {
      if (val) {
        switch (type) {
          case "onlyemail":
            this.formData.email = val.replace(/\s/g, "");
            break;
          default:
            break;
        }
      }
    },
    confirm() {
      this.submit();
    },
    getTierSaleDate(orderEventId, orderTierId, orderSaleDateId) {
      let eventTierList = this.eventTierList.find(
        (event) => event._id === orderEventId
      );

      let eventTier = eventTierList.tiers.find(
        (tier) => tier.id === orderTierId
      );
      return eventTier.saleDates.find(
        (tierSaleDate) => tierSaleDate.id === orderSaleDateId
      );
    },
    formatDate(date) {
      return dateFormat(new Date(parseInt(date)), "mm/dd/yy");
    },
    async getCurrentTicketValue() {
      if (this.isRemoveObj) {
        this.isRemoveObj = false;
      } else {
        try {
          let orderEventId =
            this.currentSelectedObj === undefined ||
            this.currentSelectedObj === null
              ? null
              : this.currentSelectedObj[1].orderEventId;

          if (orderEventId !== null) {
            let event = this.eventTierList.find((x) => x._id === orderEventId);

            let tempArr = [];
            for (const tier of event.tiers) {
              if (this.orderOrTicket === ORDER) {
                let orders = this.currentSelectedObj[1];
                let orderTierList = orders.orderTicketList;
                let maxPrice = 0;
                let orderTierId = null;
                for (const orderTier of orderTierList) {
                  if (parseFloat(orderTier.price.$numberDecimal) > maxPrice) {
                    maxPrice = parseFloat(orderTier.price.$numberDecimal);
                    orderTierId = orderTier.tier_id;
                  }
                }
                if (tier.id !== orderTierId && tier.price > maxPrice) {
                  for (const saleDate of tier.saleDates) {
                    const startDate = this.formatDate(
                      saleDate.sale_start.$date.$numberLong
                    );
                    const endDate = this.formatDate(
                      saleDate.sale_end.$date.$numberLong
                    );
                    tempArr.push({
                      label:
                        tier.name +
                        `  (${this.currencySign}` +
                        tier.price.toFixed(2) +
                        ")" +
                        " " +
                        startDate +
                        "-" +
                        endDate,
                      value: [
                        saleDate.id,
                        { tierPrice: tier.price, tierCount: orders.orderCount },
                      ],
                    });
                  }
                }
              } else {
                let orderTier = this.currentSelectedObj[1].orderTicket;
                if (
                  orderTier.tier_id !== tier.id &&
                  tier.price > parseFloat(orderTier.price.$numberDecimal)
                ) {
                  for (const saleDate of tier.saleDates) {
                    const startDate = this.formatDate(
                      saleDate.sale_start.$date.$numberLong
                    );
                    const endDate = this.formatDate(
                      saleDate.sale_end.$date.$numberLong
                    );
                    tempArr.push({
                      label:
                        tier.name +
                        `  (${this.currencySign}` +
                        tier.price.toFixed(2) +
                        ")" +
                        " " +
                        startDate +
                        "-" +
                        endDate,
                      value: [
                        saleDate.id,
                        { tierPrice: tier.price, tierCount: 1 },
                      ],
                    });
                  }
                }
              }
            }
            this.currentSelectedObj.upgrade = null;
            this.currentSelectedObj.isUpgradeAvailable = true;
            this.currentSelectedObj.option = tempArr;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    submit() {
      let orderId =
        this.orderOrTicket === ORDER
          ? this.currentSelectedObj[0]
          : this.currentSelectedObj[1].orderId;
      let refundObj = {};
      if (this.orderOrTicket === ORDER) {
        refundObj = {
          refundType: this.orderOrTicket,
          refundData: {
            orderId: orderId,
            details: this.formData.reasonForRefund,
          },
        };
      } else {
        let ticket_id = this.currentSelectedObj[1].orderTicket.validation;
        refundObj = {
          refundType: this.orderOrTicket,
          refundData: {
            orderId: orderId,
            ticketId: ticket_id,
            details: this.formData.reasonForRefund,
          },
        };
      }
      this.$axios.post("/toteswap/request-refund", refundObj);
      this.$toast("Refund Request Sent!", { type: "success" });
      this.clearForm();
    },
    autoCompleteUserInfo() {
      if (this.formData.ticket !== undefined) {
        const userInfo = this.formData.ticket[1];
        this.formData.firstName = userInfo.first_name;
        this.formData.lastName = userInfo.last_name;
        this.formData.email = userInfo.account_email;
        this.formData.phoneNumber = userInfo.phone;
      }
    },
    clearForm() {
      this.formData = {};
      this.currentSelectedObj = {};
      this.orderOrTicket = "";
      this.$formulate.reset("refundForm");
    },
  },
  async mounted() {
    try {
      const { data } = await this.$axios.post("/toteswap/get-orders", {
        plannerPolicy: "enable_refund_ticket",
        eventUrl: this.$route.params.url || '',
      });
      this.eventTierList = data.events;
      for (const order of data.orders) {
        let tempLabel = order.event_name + " - ";
        let tempValue = [
          order._id,
          {
            orderTicketList: order.tickets,
            orderCount: order.ticket_count,
            orderEventId: order.event_id,
          },
        ];

        let orderTier = order.tickets[0];

        // let eventTierSaleDate = this.getTierSaleDate(
        //   order.event_id.$oid,
        //   orderTier.tier_id,
        //   orderTier.saleDateId
        // );
        // let startDate = this.formatDate(
        //   eventTierSaleDate.sale_start.$date.$numberLong
        // );
        // let endDate = this.formatDate(
        //   eventTierSaleDate.sale_end.$date.$numberLong
        // );

        // let startDate = "N/A";
        // let endDate = "N/A";

        for (let i = 0; i < order.tickets.length; i++) {
          let ticketObj = {
            label:
              order.event_name +
              " - " +
              order.tickets[i].tier +
              `  (${this.currencySign}` +
              order.tickets[i].price.$numberDecimal +
              ") #"+ order.tickets[i].number,
            value: [
              order.tickets[i].invoice,
              {
                orderId: order._id,
                orderTicket: order.tickets[i],
                orderEventId: order.event_id,
              },
            ],
          };

          this.ticketList.push(ticketObj);

          if (i === order.tickets.length - 1) {
            tempLabel =
              tempLabel +
              order.tickets[i].tier +
              `  (${this.currencySign}` +
              order.tickets[i].price.$numberDecimal +
              ") ";
          } else {
            tempLabel =
              tempLabel +
              order.tickets[i].tier +
              `  (${this.currencySign}` +
              order.tickets[i].price.$numberDecimal +
              ") " +
              "|";
          }
        }
        let pcs = order.ticket_count === 1 ? "pc" : "pcs";
        tempLabel = tempLabel + " - " + order.ticket_count + pcs;
        this.orderList.push({ label: tempLabel, value: tempValue });
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style lang="less">
.button-refund {
  color: aqua;
  text-align: -webkit-right;

  .formulate-input-element {
    button {
      background: #00b7f1;
    }
  }
}
.row {
  margin: 0;
}
</style>
